import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Answer, ConditionQuery, OnSelection } from 'app/shared/models';
import { Observable } from 'rxjs';

import { ConfigurationService } from 'app/shared';

@Injectable({
    providedIn: 'root'
})
export class ConditionService {
    public answers: EventEmitter<Answer>;
    constructor(private _http: HttpClient, private _config: ConfigurationService) {
        this.answers = new EventEmitter();
    }

    getSelection(source: string, sourceId: number): Observable<OnSelection> {
      return this._http.post<OnSelection>(`${this._config.apiUrl}/api/condition/selection`, { source, sourceId });
    }
}
